<template>
    <div class="wrap" ref="wrap">
        <span class="spinner icon is-large"><i class="fas fa-3x fa-circle-notch"></i></span>

        <h1>Hodl</h1>
        <span class="icon is-large"><i class="fas fa-3x fa-chart-line"></i></span>

        <router-link to="/portfolio" tag="button">Continue 1</router-link>
        <router-link to="/portfolio2" tag="button">Continue 2</router-link>
    </div>
</template>

<style lang="scss" scoped>
    .wrap{
        height:100vh;
        display:flex;

        flex-direction:column;
        justify-content:center;
        align-items:center;

        button:first-of-type{
            margin-top:33vh;
            margin-bottom:1rem;
        }
    }
</style>

<script>
    import { TimelineMax } from '@/vendor';
    import screenfull from 'screenfull';

    export default {
        mounted()
        {
        },

        beforeDestroy()
        {
            if( screenfull.isEnabled )
            {
                // screenfull.request();
            }
        },

        methods: {
            getAnimateIn()
            {
                let tl = new TimelineMax();
                tl.add( 'reveal', 2 );

                tl.to( '.spinner', 2, { rotation: 900, ease: Linear.easeNone }, 0 );
                tl.from( '.spinner', 0.3, { y: 20, autoAlpha: 0 }, 0 );

                tl.to( '.spinner', 0.3, { autoAlpha: 0, position: 'absolute' }, 'reveal-=0.3' );
                tl.from( this.$refs.wrap.children, 0.01, { position: 'absolute' }, 'reveal' );
                tl.staggerFrom( this.$refs.wrap.children, 0.3, { y: 20, autoAlpha: 0 }, 0.1, 'reveal' );
                return tl;
            },

            getAnimateOut()
            {
                let tl = new TimelineMax();

                tl.staggerTo( 'h1, button', 0.3, { autoAlpha: 0 }, 0.1 );

                return tl;
            }
        }
    };
</script>
