<template>
    <div class="top-dog">
        <transition mode="out-in" name="fade">
            <v-touch v-if="!drawerIsOpen" @swipeleft="slideRight" @swiperight="slideLeft" class="container">
                <div class="slider" :style="{ marginLeft: -100 * currentSlide + 'vw' }">
                    <section class="overview">
                        <h1>Overview</h1>
                    </section>
                    <section class="trades">
                        <h1>Trades</h1>
                    </section>
                    <section class="pension">
                        <h1>Pension</h1>
                    </section>
                    <section class="piggy-banks">
                        <h1>Piggy banks</h1>
                    </section>
                </div>
            </v-touch>
        </transition>

        <transition mode="out-in" name="fade">
            <v-touch tag="ul" @swipeup="openDrawer" v-if="justSlid && !drawerIsOpen" class="indicator">
                <li v-for="n in 4" :class="{ 'active': currentSlide === n - 1 }"></li>
            </v-touch>

            <v-touch tag="span" @swipeup="openDrawer" v-else-if="!justSlid && !drawerIsOpen" class="drawer-icon"><i class="fas fa-chevron-up"></i></v-touch>
        </transition>

        <v-touch @swipeup="openDrawer" @swipedown="closeDrawer" :class="{ 'drawer': true, 'open': drawerIsOpen }" ref="drawer">
            <div class="handle">
                <span class="icon"><i class="fas fa-coins"></i></span>
            </div>

            <div class="hidden-stash">

                <div class="row" v-for="n1 in 10" :key="n1">
                    <div class="col-3" v-for="n2 in 4" :key="n2">
                        <img src="/static/images/50x50.png" />
                    </div>
                </div>

            </div>
        </v-touch>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { TimelineMax } from '@/vendor';

    export default {
        data()
        {
            return {
                currentSlide: 0,
                justSlid: true,
                drawerIsOpen: false
            };
        },

        mounted()
        {
            this.slideTimeout = setTimeout( () =>
            {
                this.justSlid = false;
            }, 5000 );
        },

        methods: {
            slideRight()
            {
                if( this.currentSlide < 3 )
                {
                    this.currentSlide++;
                }

                this.justSlid = true;
                this.startTimeout();
            },

            slideLeft()
            {
                if( this.currentSlide > 0 )
                {
                    this.currentSlide--;
                }

                this.justSlid = true;
                this.startTimeout();
            },

            startTimeout()
            {
                clearTimeout( this.slideTimeout );

                if( this.justSlid === true )
                {
                    this.slideTimeout = setTimeout( () =>
                    {
                        this.justSlid = false;
                    }, 1500 );
                }
            },

            openDrawer()
            {
                this.drawerIsOpen = true;
            },

            closeDrawer()
            {
                this.drawerIsOpen = false;
            },

            getAnimateIn()
            {
                let tl = new TimelineMax({ delay: 1.3 });

                // tl.set( 'section', { minHeight: '44px' });

                // tl.fromTo( 'section:first-of-type .icon', 1, { scale: 2.59, x: '-50%', y: '35vh' }, { scale: 1, x: '-50%', y: '0vh' }, 0 );

                // tl.from( 'section', 0.5, { width: 0, padding: 0, ease: Circ.easeInOut }, 1.3 );
                // tl.fromTo( 'section:first-of-type .icon', 0.29, { position: 'absolute', x: '-50%' }, { position: 'absolute', x: '-50%', ease: Sine.easeInOut }, 1.3 );

                // tl.staggerFromTo( 'section:not(:first-of-type) .icon', 0.3, { y: 20, opacity: 0 }, { y: 0, opacity: 1, ease: Sine.easeInOut }, 0.1, 1.5 );

                // tl.set( 'section:first-of-type .icon', { position: 'relative', x: '0%' }, 2 );

                // tl.set( 'section', { transition: 'all 0.3s ease-out' }, 2 );

                return tl;
            }
        }
    };
</script>
