<template>
    <div class="wrap">
        <section @click.self="setActive" class="overview">
            <span class="icon"><i class="fas fa-chart-line"></i></span>

            <h1 class="title">
                <span class="icon"><i class="fas fa-chart-line"></i></span> Quick Overview
            </h1>

            <div class="content">

                <div class="row">
                    <div class="col-4">
                        <span class="icon"><i class="fas fa-4x fa-chart-pie"></i></span>
                        <p>Distribution</p>
                    </div>
                    <div class="col-4">
                        <div class="best-worst">
                            <p>Top performers</p>
                            <div class="split green">
                                <span>DOGE</span>
                                <span>9.31%</span>
                            </div>
                            <div class="split green">
                                <span>DCR</span>
                                <span>17.69%</span>
                            </div>

                            <p>Worst out there</p>
                            <div class="split red">
                                <span>LTC</span>
                                <span>-18.98%</span>
                            </div>
                            <div class="split red">
                                <span>BTC</span>
                                <span>-12.52%</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="best-worst">
                            <p>Real profits</p>
                            <div class="split green">
                                <span>EUR</span>
                                <span>1525</span>
                            </div>
                            <div class="split green">
                                <span>BTC</span>
                                <span>0.257281</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="area">
                    <span class="icon"><i class="fas fa-chart-area"></i></span>
                    <h2>Cumulative</h2>
                </div>
            </div>
        </section>
        <section @click.self="setActive" class="trades">
            <span class="icon"><i class="fas fa-book"></i></span>

            <h1 class="title">
                <span class="icon"><i class="fas fa-book"></i></span> Trade entries
            </h1>

            <div class="content">
                <v-trades></v-trades>
            </div>
        </section>
        <section @click.self="setActive" class="pension">
            <span class="icon"><i class="fab fa-accessible-icon"></i></span>

            <h1 class="title">
                <span class="icon"><i class="fab fa-accessible-icon"></i></span> Pension plan
            </h1>

            <div class="content">
                <div class="pension-calc">
                    <label for="dieAt">
                        <span>Die at age {{ dieAt }}</span>
                        <input type="range" min="50" max="100" v-model="dieAt" />
                    </label>

                    <label for="monthlyPayout">
                        <span>Monthly payout €{{ dottify( monthlyPayout ) }}</span>
                        <input type="range" min="500" max="5000" step="100" v-model="monthlyPayout" />
                    </label>

                    <label for="portfolioTotal">
                        <span>Portfolio total €{{ dottify( portfolioTotal ) }}</span>
                        <input type="number" v-model="portfolioTotal" />
                    </label>

                    <p>You can retire for {{ retirementYears }} years, <br/>starting at age {{ retirementStart }}.</p>
                </div>
            </div>
        </section>
        <section @click.self="setActive" class="piggy-banks">
            <span class="icon"><i class="fas fa-piggy-bank"></i></span>

            <h1 class="title">
                <span class="icon"><i class="fas fa-piggy-bank"></i></span> Piggy Banks
            </h1>

            <div class="content">
            </div>
        </section>
    </div>
</template>

<style lang="scss" scoped>
    section:not(:first-of-type) .icon{
        opacity: 0; // fix voor anim
    }
</style>

<script>
    import { TimelineMax, TweenMax } from '@/vendor';
    import { dottify } from '@/app/mixins';
    import Trades from '@/app/components/Trades';

    export default {
        components: {
            'v-trades': Trades
        },

        mixins: [ dottify ],

        data()
        {
            return {
                dieAt: 85,
                monthlyPayout: 1500,
                portfolioTotal: 10000
            };
        },

        computed: {
            retirementYears()
            {
                return ( this.portfolioTotal / ( this.monthlyPayout * 12 ) ).toFixed( 2 );
            },

            retirementStart()
            {
                return Math.floor( this.dieAt - this.retirementYears );
            }
        },

        methods: {
            setActive( e )
            {
                let current = this.$el.querySelector( '.active' );

                if( current && current !== e.target )
                {
                    current.classList.remove( 'active' );
                }

                e.target.classList.toggle( 'active' );

                TweenMax.from( e.target.querySelector( '.title' ), 0.3, { width: 0 });
            },

            getAnimateIn()
            {
                let tl = new TimelineMax({ delay: 1.3 });

                tl.set( 'section', { minHeight: '44px' });

                tl.fromTo( 'section:first-of-type .icon', 1, { scale: 2.59, x: '-50%', y: '35vh' }, { scale: 1, x: '-50%', y: '0vh' }, 0 );

                tl.from( 'section', 0.5, { width: 0, padding: 0, ease: Circ.easeInOut }, 1.3 );
                tl.fromTo( 'section:first-of-type .icon', 0.29, { position: 'absolute', x: '-50%' }, { position: 'absolute', x: '-50%', ease: Sine.easeInOut }, 1.3 );

                tl.staggerFromTo( 'section:not(:first-of-type) .icon', 0.3, { y: 20, opacity: 0 }, { y: 0, opacity: 1, ease: Sine.easeInOut }, 0.1, 1.5 );

                tl.set( 'section:first-of-type .icon', { position: 'relative', x: '0%' }, 2 );

                tl.set( 'section', { transition: 'all 0.3s ease-out' }, 2 );

                return tl;
            }
        }
    };
</script>
