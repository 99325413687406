<template>
    <div>
        <div class="block">
            <h2>Price calculations</h2>
            <ul>
                <li v-for="( amount, coin ) in holdings">{{ coin }} - {{ amount }} - ${{ coin === 'btc' ? priceHistory[coin][0].close * amount : priceHistory[coin][0].close * amount * priceHistory.btc[0].close }}</li>
            </ul>

            <p>prices:</p>
            <p>btc: ${{ priceHistory.btc[0].close }}</p>
            <p>ltc: {{ priceHistory.ltc[0].close }} - ${{ priceHistory.ltc[0].close * priceHistory.btc[0].close }}</p>
            <p>doge: {{ priceHistory.doge[0].close }} - ${{ priceHistory.doge[0].close * priceHistory.btc[0].close }}</p>
        </div>

        <div class="block portfolio">
            <h2>Portfolio</h2>

            <ul>
                <li v-for="( amount, coin ) in portfolio">{{ amount + ' ' + coin }}</li>
            </ul>

            <p>------------------------------------------</p>

            <p>Cheatfolio:</p>
            <div v-for="coin in initialPortfolio">
                <span>{{ coin.name }}</span> <input :name="'initial' + coin.name" v-model="coin.amount" />
            </div>

            <form @submit.prevent="addInitialFund">
                <input name="addinitialcoin" v-model="addinitialcoin" placeholder="coin" />
                <input name="addinitialamount" v-model="addinitialamount" placeholder="amount" />

                <input type="submit" />
            </form>
        </div>

        <div class="block">
            <h2>Trade inputs</h2>
            <form @submit.prevent="checkAndApply">
                <label>
                    what coin:
                    <input name="from" v-model.trim="entry.from" placeholder="from" />
                </label>

                <label>
                    to what coin:
                    <input name="to" v-model.trim="entry.to" placeholder="to" />
                </label>

                <span>How many </span>
                <select class="calc-dir" name="trade-dir" v-model="entry.amountIn">
                    <option>{{ entry.from }}</option>
                    <option>{{ entry.to }}</option>
                </select>
                <span> did you trade? </span>
                <input class="amount-input" name="amount" v-model.trim="entry.amount" />
                <br/>
                <label>
                    When?
                    <input type="date" v-model="entry.date" />
                </label>
                <br/>
                <input type="submit" value="send" />
            </form>
            <p>{{ entry }}</p>
        </div>

        <div class="block">
            <h2>Trades</h2>
            <ul>
                <li v-for="trade in trades">{{ trade.lost + trade.from }} -> {{ trade.gained + trade.to }}</li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    div{
        margin-top:4rem;
        width:100%;
    }
</style>

<script>
    import BTCBTC from '@/app/data/BTCBTC.json';
    import BTCUSD from '@/app/data/BTCUSD.json';
    import LTCBTC from '@/app/data/LTCBTC.json';
    import DOGEBTC from '@/app/data/DOGEBTC.json';

    export default {
        data()
        {
            return {
                priceHistory: {
                    usd: BTCUSD,
                    btc: BTCBTC,
                    ltc: LTCBTC,
                    doge: DOGEBTC
                },

                initialPortfolio: [
                    { name: 'usd', amount: 0 },
                    { name: 'btc', amount: 0 }
                ],

                addinitialcoin: null,
                addinitialamount: null,

                entry: {
                    from: null,
                    to: null,
                    amountIn: null,
                    amount: null,
                    when: null,
                    date: null
                },

                trades: []
            };
        },

        computed: {
            holdings()
            {
                return this.$store.getters.holdings;
            },

            // formattedData()
            // {
            //     if( !this.btcusdData )
            //     {
            //         return null;
            //     }

            //     let newData = this.btcusdData.sort( ( a, b ) =>
            //     {
            //         if( a.close > b.close )
            //         {
            //             return -1;
            //         }
            //         else if( a.close < b.close )
            //         {
            //             return 1;
            //         }

            //         return 0;
            //     });

            //     return newData;
            // },

            portfolio()
            {
                let portfolio = {};

                for( let i = 0; i < this.initialPortfolio.length; i++ )
                {
                    portfolio[ this.initialPortfolio[i].name ] = parseFloat( this.initialPortfolio[i].amount );
                }

                for( let i = 0; i < this.trades.length; i++ )
                {
                    if( !portfolio[ this.trades[i].to ] )
                    {
                        portfolio[ this.trades[i].to ] = 0;
                    }

                    portfolio[ this.trades[i].from ] -= parseFloat( this.trades[i].lost );
                    portfolio[ this.trades[i].to ] += parseFloat( this.trades[i].gained );
                }

                return portfolio;
            }
        },

        watch: {
            'entry.date': function( pVal )
            {
                this.entry.when = new Date( pVal ).getTime().toString().slice( 0, -3 );
            }
        },

        mounted()
        {
        },

        methods: {
            checkAndApply()
            {
                let priceFromAtTime = this.priceHistory[ this.entry.from ].find( day =>
                {
                    return day.time >= this.entry.when;
                }).close;

                let priceToAtTime = this.priceHistory[ this.entry.to ].find( day =>
                {
                    return day.time >= this.entry.when;
                }).close;

                let lost = null;
                let gained = null;

                // flip the usd, bugtest dit
                if( this.entry.amountIn === 'usd' )
                {
                    priceToAtTime = 1 / priceToAtTime;
                    priceFromAtTime = 1 / priceFromAtTime;
                }

                console.log( 'to, from', priceToAtTime, priceFromAtTime );

                // flip the btc, doet wss niks meer want 1 / 1
                if( this.entry.amountIn === 'btc' && this.entry.amountIn === this.entry.to )
                {
                    priceToAtTime = 1 / priceToAtTime;
                }
                else if( this.entry.amountIn === 'btc' && this.entry.amountIn === this.entry.to )
                {
                    priceFromAtTime = 1 / priceFromAtTime;
                }

                if( this.entry.amountIn === this.entry.from )
                {
                    if( this.entry.amountIn === 'btc' )
                    {
                        lost = this.entry.amount;
                        gained = this.entry.amount / priceToAtTime;
                    }
                    else
                    {
                        gained = this.entry.amount * priceFromAtTime;
                        lost = this.entry.amount;
                    }
                }
                else
                {
                    if( this.entry.amountIn === 'btc' )
                    {
                        gained = this.entry.amount;
                        lost = this.entry.amount / priceFromAtTime;
                    }
                    else
                    {
                        lost = this.entry.amount * priceToAtTime;
                        gained = this.entry.amount;
                    }
                }

                this.trades.push({
                    from: this.entry.from,
                    to: this.entry.to,
                    lost: lost,
                    gained: gained,
                    time: this.entry.when
                });
            },

            addInitialFund()
            {
                console.log( this.addinitialamount );
                this.initialPortfolio.push({
                    name: this.addinitialcoin,
                    amount: this.addinitialamount
                });

                this.addinitialcoin = null;
                this.addinitialvalue = null;
            }
        }
    };
</script>
