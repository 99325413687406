<template>
    <div id="app" :class="{ [ $route.name.toLowerCase() + '-page' ]: true }">
        <transition mode="out-in"
                    @enter="getAnimateIn"
                    @leave="getAnimateOut"
                    :css="false"
                    appear>
            <router-view :key="$route.path" ref="currentView"></router-view>
        </transition>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';
</style>

<script>
    import { TimelineMax } from '@/vendor';

    export default {
        name: 'App',

        methods: {
            getAnimateIn( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateIn === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateIn(), 0 );
                }
            },

            getAnimateOut( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateOut === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateOut(), 0 );
                }
            }
        }
    };
</script>
