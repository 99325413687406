import Vue from 'vue';
import Router from 'vue-router';

import Splash from '@/app/pages/Splash';
import Portfolio from '@/app/pages/Portfolio';
import Portfolio2 from '@/app/pages/Portfolio2';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Splash',
            component: Splash
        },
        {
            path: '/portfolio',
            name: 'Portfolio',
            component: Portfolio
        },
        {
            path: '/portfolio2',
            name: 'Portfolio2',
            component: Portfolio2
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
